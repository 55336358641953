import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  PlanResponseModel,
  Price
} from '~/app/application/features/auth/domain/models/plans/plan-response-model';
import { SubscriptionModel } from '~/app/application/features/auth/domain/models/subscriptions/create-subscription-model';
import { useRegisterUserDataStore } from '~/app/application/features/auth/store/slice/register-user-data-slice';
import CreditCardForm from '~/app/application/features/components/credit-card-form/credit-card-form';
import QuantityCarTextField from '~/app/application/features/components/quantity-car-text-field/quantity-car-text-field';
import RadioPlanLabel from '~/app/application/features/components/radio-plan-label/radio-plan-label';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';
import BaseRadioButton from '~/app/core/presentation/components/base-radio-button/base-radio-button';
import { APP_ENV } from '~/env';

import PaymentResumeBox from '../../../../components/payment-resume-box/payment-resume-box';
import SettingPlan from '../../../../components/success-setting/success-setting';
import {
  useCreateSubscriptionMutation,
  useGetPlansMutation,
  useUpdateSubscriptionMutation
} from '../../../store/hooks';
import {
  ContentBox,
  Title,
  PaymentPlanBox,
  HorizontalContainer,
  CreditCardContainer,
  RootBox,
  PaymentPlanContainer,
  CarQuantityContainer,
  PaymentPlanBoxContainer,
  CarQuantityInputContainer
} from './upgrade-payment-plan-styles';
import { PaymentPlanValidator } from './validators/payment-plan.validator';

type PaymentPlanFormData = {
  carQuantity: string;
  cardBrand: 'visa' | 'mastercard' | 'american_express';
  cardNumber: string;
  holderName: string;
  holderDocument: string;
  price: string;
  expirationDate: string;
  cvv: string;
};

const parseDateFromMMYY = (dateString: string): Date => {
  const parts = dateString.split('/');
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[1], 10) + 2000; 
  return new Date(year, month - 1, 1);
};

const UpgradePaymentPlan = () => {
  const { t } = useTranslation('payment-upgrade-plan');
  const { registerState, setRegisterState } = useRegisterUserDataStore();
  const [updateSubscription, { isLoading }] = useUpdateSubscriptionMutation();
  const [createSubscription, { isLoading: isLoadingCreate }] =
    useCreateSubscriptionMutation();
  const [plan, setPlan] = useState<PlanResponseModel>(registerState.plans[3]);
  const [getPlans] = useGetPlansMutation();
  const [monthlyPrice, setMonthlyPrice] = useState<Price>();
  const [yearlyPrice, setYearlyPrice] = useState<Price>();
  const [selectedPrice, setSelectedPrice] = useState<Price>();
  const [code, setCode] = useState('');
  const [getPrice, setGetPrice] = useState('0,00');
  const [displayPaymentSuccess, setdisplayPaymentSuccess] = useState(false);
  const [displayPaymentError, setDisplayPaymentError] = useState(false);

  const handleSavePrice = (priceValue: string) => {
    setGetPrice(priceValue);
  };

  const updateSubscriptionRequest = (values: SubscriptionModel) => {
    updateSubscription({ ...values, isSelfOnboarding: true })
      .unwrap()
      .then((response) => {
        setdisplayPaymentSuccess(true);
        setCode(response.code);
      })
      .catch(() => {
        setDisplayPaymentError(true);
        console.error('error in update payment');
      });
  };

  const createSubscriptionRequest = (values: SubscriptionModel) => {
    createSubscription({ ...values, isSelfOnboarding: true })
      .unwrap()
      .then((response) => {
        setdisplayPaymentSuccess(true);
        setCode(response.code);
      })
      .catch(() => {
        setDisplayPaymentError(true);
        console.error('error in create payment');
      });
  };

  useEffect(() => {
    if (plan) {
      plan.products[0].prices.forEach((price) => {
        if (price.frequency === 'yearly') {
          setYearlyPrice(price);
        } else {
          setMonthlyPrice(price);
        }
      });
    }
  }, [plan]);

  const onSubmit = (data: PaymentPlanFormData) => {
    const subscriptionId = registerState.companies[0].subscriptions[0];
    const companyId = registerState?.companies[0]?._id;

    const commonRequestParams = {
      companyId,
      planId: plan._id,
      paymentMethodType: 'credit_card',
      productItems: [
        {
          productId: plan.products[0]._id,
          quantity: registerState?.vehiclesCount,
          priceId: data.price,
          discounts: []
        }
      ],
      paymentProfile: {
        holderName: data.holderName,
        holderDocument: data.holderDocument,
        cardExpiration: parseDateFromMMYY(data.expirationDate),
        cardNumber: data.cardNumber.replaceAll(' ', ''),
        cardCVV: data.cvv,
        cardBrand: data.cardBrand
      },
      invoiceSplit: false,
      subscriptionAffiliates: [],
      metaData: []
    };

    if (subscriptionId) {
      return updateSubscriptionRequest({
        subscriptionId,
        ...commonRequestParams
      });
    } else {
      return createSubscriptionRequest({
        ...commonRequestParams
      });
    }
  };

  const formData = useForm<PaymentPlanFormData>({
    resolver: PaymentPlanValidator
  });

  const { handleSubmit, setValue, control, formState } = formData;

  const selectedPriceId = useWatch({ control, name: 'price' });
  useEffect(() => {
    const price = plan?.products[0]?.prices?.find(
      (price) => price?._id === selectedPriceId
    );
    setSelectedPrice(price);
  }, [selectedPriceId]);

  const displayPayment = !(displayPaymentSuccess || displayPaymentError);

  useEffect(() => {
    getPlans({})
      .unwrap()
      .then((response) => {
        setPlan(response[3]);
      })
      .catch((error) => {
        setDisplayPaymentError(true);
        console.error('Error in fetching plans:', error);
      });
  }, [getPlans, setPlan]);

  useEffect(() => {
    formData.setValue('carQuantity', registerState.vehiclesCount.toString());
  }, []);

  useEffect(() => {
    const carQuantity = formData.watch('carQuantity');

    if (carQuantity) setRegisterState({ vehiclesCount: Number(carQuantity) });
  }, [formData.watch('carQuantity')]);

  return (
    <PaymentPlanContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        {displayPayment && (
          <PaymentPlanBox>
            <RootBox>
              <ContentBox>
                <Title>{t('title')}</Title>

                {/* TODO: remove this input */}
                {/* <CarQuantityContainer>
                  {t('manageVehicles')}
                  <CarQuantityInputContainer>
                    <QuantityCarTextField
                      name='carQuantity'
                      control={formData.control}
                    />
                  </CarQuantityInputContainer>
                </CarQuantityContainer> */}
                <HorizontalContainer>{t('billingCycle')}</HorizontalContainer>
                {yearlyPrice && monthlyPrice && (
                  <HorizontalContainer>
                    <BaseRadioButton
                      control={formData.control}
                      row
                      name='price'
                      labelid='price'
                      alignItems='flex-start'
                      values={[
                        {
                          value: yearlyPrice?._id,
                          label: <RadioPlanLabel price={yearlyPrice} />
                        },
                        {
                          value: monthlyPrice?._id,
                          label: <RadioPlanLabel price={monthlyPrice} />
                        }
                      ]}
                    />
                  </HorizontalContainer>
                )}
                <CreditCardContainer>
                  <CreditCardForm
                    setValue={setValue}
                    control={formData.control}
                  />
                </CreditCardContainer>
              </ContentBox>
              <PaymentPlanBoxContainer>
                <PaymentResumeBox
                  handleSavePrice={handleSavePrice}
                  showCountVehicles
                  price={selectedPrice}
                  backgroundPrimary
                />
                <BaseButton
                  disabled={
                    !formState.isDirty ||
                    !formState.isValid ||
                    isLoading ||
                    isLoadingCreate
                  }
                  id='create-payment'
                  isLoading={isLoading || isLoadingCreate}
                  type='submit'
                >
                  {t('submit')}
                </BaseButton>
              </PaymentPlanBoxContainer>
            </RootBox>
          </PaymentPlanBox>
        )}
      </form>
      {displayPaymentSuccess && (
        <SettingPlan
          status='success'
          title={t('success.title')}
          description={t('success.description', {
            price: getPrice,
            code
          })}
          textInfo={t('success.textInfo', {
            plates: formData.watch('carQuantity')
          })}
          buttonLink={APP_ENV.LINK_ADD_PLATE}
          buttonLabel={t('success.buttonLabel')}
        />
      )}
      {displayPaymentError && (
        <SettingPlan
          status='error'
          title={t('error.title')}
          description={t('error.description')}
          buttonLink={() => setDisplayPaymentError(false)}
          buttonLabel={t('error.buttonLabel')}
          disableLogo={true}
        />
      )}
    </PaymentPlanContainer>
  );
};

export default UpgradePaymentPlan;
